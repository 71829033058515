.updateForm {
    text-align: center;
    margin-top: 5rem;
}

form {
    border: 2px solid #4B4F54;
    padding: 30px;
}

img {
    height: auto;
    margin-left: 90px;
    display: block;
}

.updateForm table tr img {
    height: 120px;
    margin-left: 90px;
    margin-bottom: 10px;
    display: block;
}

.App-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.formTable{
    width: 330px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    border-spacing: 10px 0px;
}

.formTable .formLabel {
    text-align: left;
    padding: 5px;
}

.formTable .formInput {
    text-align: left;
    padding: 5px;
}

.formTable .datePicker {
    width: 190px;
}

.updateForm button,
.updateForm button:focus
{
    background-color: #4B4F54;
    outline: none;
    border: 0px;
    margin-right: 1rem;
    margin-left: 1rem;
}

.updateForm button:hover,
.updateForm button:active {
    background-color: black;
    outline: none;
    border: 0px;
    margin-right: 1rem;
    margin-left: 1rem;
}