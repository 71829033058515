.footer-title{
    font-size: 12px;
    color: #bcbdbc;
    padding: 20px 0px !important;
    display: block;
}

.footer_i8n_dropup{
    font-size: 14px !important;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
@media (min-width: 992px) {
    /* desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop */
    .footer__quick-links .f12 li a { 
        margin-right: 25px;
    }
    .footer__quick-links .f12 li a {  
        font-size: 12px !important;
        color: #bcbdbc !important;
        display: block; 
    }
    .foot-text-align{
        padding-left: 15px !important;
    }
    .footer-container{
        
    }
}
@media (max-width: 991.98px) {
    /* ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad */
    
    .footer__quick-links .f12 li a {  
        font-size: 12px !important;
        color: #bcbdbc !important;
        display: block; 
        margin-right: 20px;
    }
    .foot-text-align{
        padding-left: 15px  !important;
    }
    .footer-container{
        
    }
}
@media (max-width: 575.98px) {
    /* iphone iphone iphone iphone iphone iphone iphone iphone iphone iphone    */
    .footer__quick-links .f12 li a {  
        font-size: 14px !important;
        color: #fff !important;
        display: block; 
        padding-bottom: 2px;
    }
    .foot-text-align{
        padding-left: 0px  !important;
    }
    .footer-container{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .mcSec2Anchors a{
        line-height: 2.3;
        font-size: 1.2rem;
        color: #bcbdbc;
        font-size: 12px;
        padding-left: 15px;
    }
}
.footer__quick-links .f12 li a:hover {  
    font-size: 12px !important;
    color: #a52636 !important;
    display: block;
}
.social-icons-ul{
    /* padding-left: 0px !important;
    color: red !important; */
}
footer {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
    background-color: #404040;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0;
    color: #fff;
    position: relative
}

    footer p {
        color: #fff
    }

    footer ul {
        list-style: none;
        margin-bottom: 0
    }

    footer a {
        color: #fff;
        text-decoration: none;
        -webkit-transition: 400ms ease-in-out;
        -o-transition: 400ms ease-in-out;
        transition: 400ms ease-in-out;
        display: block
    }

        footer a:hover {
            text-decoration: none;
            /* color: #fff */
            color: #a52636 !important;
        }

    footer .select select, footer .select {
        background: rgba(25,25,25,.3)
    }

        footer .select:hover {
            background: rgba(25,25,25,.3)
        }

        footer .select:after {
            background: rgba(25,25,25,.3)
        }

    footer .dropdown--custom-footer {
        position: absolute;
        bottom: 0;
        right: 0
    }

        footer .dropdown--custom-footer select {
            padding: 10px;
            background: none;
            background: rgba(0,0,0,.2);
            color: #fff;
            margin-left: -30px;
            padding-left: 30px;
            border: 0
        }

        footer .dropdown--custom-footer option {
            line-height: 24px
        }

            footer .dropdown--custom-footer option:selected {
                padding: 10px;
                color: #fff;
                background: none
            }

        footer .dropdown--custom-footer .dropup-options {
            background: rgba(0,0,0,.8)
        }

            footer .dropdown--custom-footer .dropup-options:hover {
                background: red
            }

     .footer__copyright {
        color: #747679;
        font-size: 12px;
    }

.footer__quick-links {
    margin: 20px 0;
    /* margin-left:15px !important; siva */
}

    .footer__quick-links li {
        margin-bottom: 15px
    }

        .footer__quick-links li:last-child {
            margin-bottom: 0
        }

        .footer__quick-links li a {
            font-size: 14px;
            line-height: 17px;
            margin-right: 25px;
            text-transform: uppercase;
            font-weight: 500
        }

@media screen and (min-width:768px) {
    .footer__quick-links li a {
        text-transform: initial;
        font-weight: 500
    }

        .footer__quick-links li a.text-inherit {
            color: #bcbdbc !important;
            text-transform: inherit;
            /* font-size: 1.2rem; */
            font-weight: 500
        }
}

.footer__quick-links li a.text-inherit {
    text-transform: inherit
}

.footer--services ul li a {
    color: #bcbdbc;
    font-size: 12px;
    line-height: 24px
}

    .footer--services ul li a:active, .footer--services ul li a:focus, .footer--services ul li a:hover {
        color: #fff
    }

@media screen and (max-width:767px) {
    .footer .active {
        -webkit-transition: background-color 0.5s ease-in-out;
        -o-transition: background-color 0.5s ease-in-out;
        transition: background-color 0.5s ease-in-out
    }

        .footer .active .footer-list {
            max-height: 500px;
            -webkit-transition: max-height 0.5s;
            -o-transition: max-height 0.5s;
            transition: max-height 0.5s
        }

    .footer-menus > li {
        border-bottom: 2px solid #747678
    }

        .footer-menus > li:first-child {
            border-top: 2px solid #747678
        }

        .footer-menus > li h4.title {
            /* line-height: 1.4rem; */
            /* font-size: 1.4rem; */
            text-transform: inherit;
            font-weight: 500
        }

            .footer-menus > li h4.title .footer-drop-down {
                right: 0;
                position: absolute;
                top: -webkit-calc(42%);
                top: -webkit-calc(50% - 8px);
                top: calc(50% - 8px);
                /* width: 14px;
                height: 8px; */
                background-position: 0 -930px;
                transform: scale(.8);
                -ms-transform: scale(.8);
                -webkit-transform: scale(.8)
            }

                .footer-menus > li h4.title .footer-drop-down:before {
                    font-size: 2rem
                }

    .footer .active .footer-drop-down {
        transform: rotate(180deg) scale(.8) !important;
        -ms-transform: rotate(180deg) scale(.8) !important;
        -webkit-transform: rotate(180deg) scale(.8) !important
    }

    .footer-list > li {
        padding: 0 0 5px 15px
    }
}

@media (min-width:768px) {
    .footer__quick-links ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        /* padding: 45px 0; siva */
        margin-bottom: 0;
        padding-left: 0px !important;
    }

    .footer__quick-links a:hover {
        -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px)
    }

    .footer__quick-links.footer__quick-links--primary {
        padding: 20px 0 40px !important;   
        margin-bottom: 0;
    }
}

.orthoLogoTag {
    width: 100%;
    float: right;
    height: auto;
}


.footer {
    -webkit-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out
}

.footer-content {
    margin: 20px auto
}

.footer ul {
    margin: 0;
    padding: 0
}

.footer-menus {
    width: 100%
}

.col-lg-5ths, .col-md-5ths, .col-sm-5ths, .col-xs-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.footer-menus > li .title {
    margin: 0;
    width: 100%;
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 1.06666667rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    padding: 20px 0
}

.footer-list {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s
}

    .footer-list > li > a {
        line-height: 2.3;
        /* font-size: 1.2rem; siva */
        font-size: 12px; 
        color: #bcbdbc
    }

        .footer-list > li > a:active, .footer-list > li > a:focus, .footer-list > li > a:hover {
            color: #fff
        }

@media screen and (min-width:768px) {
    .col-md-5ths {
        width: 25%;
        float: left
    }

    .col-md-5ths--2x {
        width: 50%
    }

        .col-md-5ths--2x ul {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2
        }

    .footer-menus > li .title {
        width: auto;
        cursor: default;
        color: #bcbdbc;
        text-transform: inherit;
        /* font-size: 1.2rem;siva */
        font-size: 12px; 
        font-weight: 500
    }

    .footer-list {
        max-height: none
    }
}

.footer__quick-links.footer__quick-links--social li:last-child a {
    margin-right: 0
}

.footer__quick-links.footer__quick-links--social li a {
    font-size: 18px;
    font-weight: 400;
}

@media (min-width:768px) {
    .divider-md {
        height: 1px;
        background-color: #fff
    }
}

.footer .fab {
    font-size: 18px
}

.back-to-top {
    bottom: 30px;
    height: 45px;
    position: fixed;
    right: 30px;
    width: 45px;
    z-index: 1060
}

    .back-to-top a {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border: 1px solid #d02c2e;
        background-color: #fff;
        color: #d02c2e;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 45px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        left: 0;
        position: absolute;
        text-align: center;
        text-decoration: none;
        top: 0;
        width: 45px
    }

    .back-to-top svg.icon {
        -webkit-transform: rotate(180deg) translateX(-5px) translateY(3px);
        -ms-transform: rotate(180deg) translateX(-5px) translateY(3px);
        transform: rotate(180deg) translateX(-5px) translateY(3px);
        fill: #d02c2e;
        width: 20px;
        height: 20px
    }

@media (max-width:576px) {
    .back-to-top {
        bottom: 15px;
        height: 40px;
        right: 10px;
        width: 40px
    }

        .back-to-top a {
            height: 40px;
            width: 40px
        }
}

/* --------------------------- */
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}
.text-capitalize {
    text-transform: capitalize !important
}

.footer a:hover, .footer .text-inherit:hover {
    color: #a52636 !important;
}
.footer a {
    cursor:pointer !important;
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}