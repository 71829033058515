.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    background-color: #d02c2e !important;
    display: flex;
    justify-content:space-between;
    align-items: center;
    box-shadow: 0px 2px 20px #888888;
    padding: 0 0.5%;
    z-index: 10;
    font-weight: 400;
    line-height: 1.5;
}

nav{
    float: right;
    position: inherit;
    right: 2%;

}

.header nav div{
    display: inline-flex;
    align-items: center;
    height: 4rem;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0%;
    color: white;
}

.main-image {
    position: relative;
    left: 0px;
    width: 24%;
    height: 2.3rem;
    z-index: 0;
    overflow: hidden;
}

.main-image img {
    border-radius: 5px;
    width: 60%;
    height: 100%;
    object-fit: contain;
    /* transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem); */
}

.header a {
    color: White;
    height: 4rem;
    padding-top: 1.39rem;
    padding-bottom: 1.39rem;
    text-decoration: none;
    font-family: sans-serif;
    font-style: bold;
    font-size: large;
}

.header nav div:hover,
.header nav a:active,
.header nav a:focus,
.header nav a.active {
    background-color: #4B4F54;
    border: 0px;
    color: white;
    cursor: pointer;
}

.header button,
.header .button:focus .header .button:hover .header .button:active {
    background-color: #d12b2d;
    outline: none;
    border: 0px;
    margin-right: 1rem;
    margin-left: 1rem;
}


@media (min-width: 576px) { 
    .header { 
        max-width: 100%;
    }
}

/* @media (min-width: 768px) { ... }

@media (min-width: 992px) { ... }

@media (min-width: 1200px) { ... }

@media (min-width: 1400px) { ... } */