.ListView {
    width: 100%;
    height: 100%;
    margin-top: 5rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

h2 {
    text-align: center;
}

.listTable {
    /* border: 2px solid #4B4F54; */
    border-radius: 10px;
    width: 100%;
    /* height: 200px; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    border-spacing: 10px 0px;
    table-layout: fixed;
}

.listTable th {
    background-color: #4B4F54;
    border-bottom: 1px solid black;
    color: white;
    padding: 5px;
}

.listTable td {
    text-align: left;
    border: 1px solid #4B4F54;
    /* background-color: grey; */
    border-bottom: 1px solid grey;
    border-radius: 10px;
    padding: 5px;
}

.listTable th:first-child, .listTable td:last-child{
    border-top-left-radius: 10px;
}

.listTable th:last-child {
    border-top-right-radius: 10px;
}

.iconButton{
    border: none;
    background-color: white;
    cursor: pointer;
}

.iconDiv{
    margin-left: 0%;
}

.icons{
    height: 20px;
    width: 20px;
    align-items: left;
    margin-right: auto;
    margin-left: 10px;
    margin-top: 5px;
}

.downloadDiv{
    width: 100%;
    align-items: center;
    display: flex;
}

.download{
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.download button,
.download button:focus
.download button:hover
.download button:active{
    background-color: #4B4F54;
    outline: none;
    border: 0px;
    margin-right: 1rem;
    margin-left: 1rem;
}

.footer{
    margin-top: 5rem;
}
/* 
@media (min-width: 576px) { 

 }

@media (min-width: 768px) { ... }

@media (min-width: 992px) { ... }

@media (min-width: 1200px) { ... }

@media (min-width: 1400px) { ... } */