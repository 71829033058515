.input {
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.input .inner {
  margin-left: auto;
  margin-right: auto;
}

.input button {
  background-color: transparent;
  border: none;
  outline: none;
  width: 2rem;
  padding: 0%;
}

.input button:focus,
.input button:active,
.input button:hover {
  background-color: transparent;
  border: none;
  outline: none;
}

.input div{
  display: inline;
}

.input button img{
  height: 1.6rem;
  width: 1.6rem;
  margin: 0%;
}

.input label {
  font-weight: bold;
  margin-right: 1rem;
}

.input button {
  margin-left: 5px;
}

.input input {
  margin: 7px;
  width: 10rem;
  height: 2rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font: inherit;
  padding-left: 0.5rem;
}

.input .download{
  display: block;
}